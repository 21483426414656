.card {
  border: solid #e8e8e8;
  border-width: 1.25px;
  border-radius: 20px;
  padding: 20px;
  background-color: white;
  justify-content: center;
  display: flex;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.grid-item {
  text-align: center;
  width: 100%;
  max-width: 440px;
}

#one {
  justify-self: center;
  margin-top: 20px;
}
#two {
  justify-self: center;
  margin-top: 20px;
}
#three {
  justify-self: center;
  margin-top: 20px;
}
#four {
  justify-self: center;
  margin-top: 20px;
}

.featuresImage {
  width: 100%;
  display: block;
  border-radius: 10px;
}

@media (min-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .card {
    border-radius: 40px;
  }
  .featuresImage {
    border-radius: 20px;
  }
}
@media (min-width: 1200px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-item {
    max-width: 440px;
  }
  #one {
    justify-self: end;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  #two {
    justify-self: start;
    margin-left: 10px;
    margin-bottom: 10px;
  }
  #three {
    justify-self: end;
    margin-right: 10px;
    margin-top: 10px;
  }
  #four {
    justify-self: start;
    margin-left: 10px;
    margin-top: 10px;
  }
}
