.conectoryTitle {
  width: 100%;
  max-height: 80px;
}

.mapsMockups {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  overflow: hidden;
  margin-left: -20px;
}
.mockupWidth {
  width: 200px;
}

.visibility {
  opacity: 0%;
}

.grid-container-home {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /* grid-template-columns: auto auto; */
}

.grid-item-home {
  text-align: center;
  width: 100%;
}

.grid-item-image-left {
  text-align: center;
}
.grid-image {
  max-height: 600px;
  max-width: 100%;
}

.grid-item-text-right {
  align-self: center;
  text-align: end;
}

.responsiveTopMargin {
  margin-top: 150px;
}

#card-margin-and-padding-1 {
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}
#card-margin-and-padding-2 {
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}
#card-margin-and-padding-3 {
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  .mapsMockups {
    overflow: visible;
    margin-left: 0px;
    width: auto;
  }
  .mockupWidth {
    width: 300px;
  }
  #card-margin-and-padding-1 {
    margin-top: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  #card-margin-and-padding-2 {
    margin-top: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  #card-margin-and-padding-3 {
    margin-top: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media (min-width: 1200px) {
  .mapsMockups {
    overflow: visible;
    width: auto;
    margin-left: 0px;
  }
  .mockupWidth {
    width: 300px;
  }
}

@media (min-width: 1400px) {
  .grid-container-home {
    /* grid-template-columns: repeat(2, 1fr); */
    grid-template-columns: auto 1fr;
  }

  .grid-item-image-right {
    margin-left: 10px;
  }
  .grid-item-image-left {
    text-align: start;
    margin-right: 10px;
  }

  .grid-item-text-right {
    align-self: center;
    text-align: end;
    max-width: 400px;
    margin-left: 10px;
  }
  .grid-item-text-left {
    align-self: center;
    text-align: end;
    max-width: 400px;
    padding-right: 10px;
  }

  #card-margin-and-padding-1 {
    margin-top: 40px;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  #card-margin-and-padding-2 {
    margin-top: 40px;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  #card-margin-and-padding-3 {
    margin-top: 40px;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
