.mapImage {
  max-width: 100%;
  max-height: 418px;
}
.item1 {
  position: absolute;
  transform: rotate(-15deg);
  left: 36.56%;
  top: 55.02%;
}
.item2 {
  position: absolute;
  transform: rotate(11deg);
  left: 55.41%;
  top: 20.92%;
}
.item3 {
  position: absolute;
  transform: rotate(-8deg);
  left: 25.04%;
  top: 10.62%;
  z-index: 1;
}
.item4 {
  position: absolute;
  transform: rotate(15deg);
  top: 33.81%;
  right: 0%;
}
.item5 {
  position: absolute;
  display: none;
  transform: rotate(3deg);
  top: -7.17%;
  right: -9.12%;
}
.item6 {
  position: absolute;
  display: none;
  transform: rotate(-6deg);
  left: -28.64%;
  top: 26.07%;
}
.item7 {
  position: absolute;
  display: none;
  transform: rotate(6deg);
  right: -28.46%;
  bottom: 0%;
}
.item8 {
  position: absolute;
  left: 77.55%;
  top: -26.07%;
}
.item9 {
  position: absolute;
  top: -10.09%;
  right: -7.51%;
}
.item10 {
  position: absolute;
  transform: rotate(-15deg);
  left: 3.7%;
  top: -20.04%;
}
.item11 {
  position: absolute;
  right: -2.48%;
  bottom: -7.65%;
}
.item12 {
  position: absolute;
  left: -3.5%;
  top: 101.72%;
}
.item13 {
  position: absolute;
  transform: rotate(-3deg);
  bottom: -40%;
  right: 12.59%;
}
.item14 {
  position: absolute;
  transform: rotate(3deg);
  display: none;
  left: 12.59%;
}
.item15 {
  position: absolute;
  display: none;
  transform: rotate(-15deg);
  right: 3.1%;
  bottom: -16.02%;
}
.item16 {
  position: absolute;
  transform: rotate(-4deg);
  left: 46.53%;
  top: -20.81%;
}
.item17 {
  position: absolute;
  transform: rotate(-5deg);
  left: -10px;
  top: -3.82%;
}
.item18 {
  position: absolute;
  left: -2.84%;
  top: 35.44%;
}
.item19 {
  position: absolute;
  transform: rotate(-5deg);
  top: 15.16%;
  right: -2.12%;
}
.item20 {
  position: absolute;
  transform: rotate(8deg);
  top: 70.45%;
  right: -3.64%;
}
.item21 {
  position: absolute;
  transform: rotate(4deg);
  left: -10px;
  bottom: 10%;
}
.item22 {
  position: absolute;
  transform: rotate(-3deg);
  left: 35.4%;
  bottom: -5.19%;
}

@media (min-width: 587px) {
  .mapImage {
    width: 547px;
    height: 418px;
  }
  .item1 {
    position: absolute;
    transform: rotate(-15deg);
    left: 200px;
    top: 230px;
  }
  .item2 {
    position: absolute;
    transform: rotate(11deg);
    left: 232px;
    top: 146px;
  }
  .item3 {
    position: absolute;
    transform: rotate(-8deg);
    left: 44px;
    top: 128px;
  }
  .item4 {
    position: absolute;
    transform: rotate(15deg);
    top: 141px;
    right: 0px;
  }
  .item5 {
    position: absolute;
    display: block;
    transform: rotate(3deg);
    top: -30px;
    right: -50px;
  }
  .item6 {
    position: absolute;
    display: block;
    transform: rotate(-6deg);
    left: -157px;
    top: 109px;
  }
  .item7 {
    position: absolute;
    display: block;
    transform: rotate(6deg);
    right: -156px;
    bottom: 0px;
  }
  .item8 {
    position: absolute;
    left: 425px;
    top: -109px;
  }
  .item9 {
    position: absolute;
    top: 38px;
    right: -96px;
  }
  .item10 {
    position: absolute;
    transform: rotate(-15deg);
    left: -97px;
    top: 42px;
  }
  .item11 {
    position: absolute;
    right: -41px;
    bottom: -32px;
  }
  .item12 {
    position: absolute;
    left: -74px;
    top: 467px;
  }
  .item13 {
    position: absolute;
    display: block;
    transform: rotate(-3deg);
    top: 390px;
    right: 69px;
  }
  .item14 {
    position: absolute;
    display: block;
    transform: rotate(3deg);
    left: 69px;
    top: 420px;
  }
  .item15 {
    position: absolute;
    display: block;
    transform: rotate(-15deg);
    right: 17px;
    bottom: -67px;
  }
  .item16 {
    position: absolute;
    transform: rotate(-4deg);
    left: 255px;
    top: -87px;
  }
  .item17 {
    position: absolute;
    transform: rotate(-5deg);
    left: -30px;
    top: -16px;
  }
  .item18 {
    position: absolute;
    left: -43px;
    top: 52px;
  }
  .item19 {
    position: absolute;
    transform: rotate(-5deg);
    top: 101px;
    right: -50px;
  }
  .item20 {
    position: absolute;
    transform: rotate(8deg);
    top: 236px;
    right: -20px;
  }
  .item21 {
    position: absolute;
    transform: rotate(4deg);
    left: -70px;
    top: 313px;
  }
  .item22 {
    position: absolute;
    transform: rotate(-3deg);
    left: -68px;
    bottom: 5px;
  }
}