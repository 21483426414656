.sidePadding {
  padding-left: 20px;
  padding-right: 20px;
}
.extraSidePadding {
  padding-left: 20px;
  padding-right: 20px;
}

.fontBody {
  font-size: 16px;
  color: black;
}

.fontLargestTitle {
  font-size: 28px;
  color: black;
  font-weight: bold;
}

.fontLargeTitle {
  font-size: 21px;
  color: black;
  font-weight: 700;
}

.featuresSidePadding {
  padding-left: 20px;
  padding-right: 20px;
}

/* @media (min-width: 576px) {
  .sidePadding {
    padding-left: 20px;
    padding-right: 20px;
  }
} */
@media (min-width: 768px) {
  .sidePadding {
    padding-left: 40px;
    padding-right: 40px;
  }
  .extraSidePadding {
    padding-left: 40px;
    padding-right: 40px;
  }
  .fontBody {
    font-size: 17px;
    color: black;
  }
  .fontLargestTitle {
    font-size: 34px;
    color: black;
    font-weight: bold;
  }
  .fontLargeTitle {
    font-size: 24px;
  }

  .featuresSidePadding {
    padding-left: 200px;
    padding-right: 200px;
  }
}
@media (min-width: 1200px) {
  .sidePadding {
    padding-left: 250px;
    padding-right: 250px;
  }
  .extraSidePadding {
    padding-left: 250px;
    padding-right: 250px;
  }
  .fontBody {
    font-size: 19px;
    color: black;
  }
  .fontLargestTitle {
    font-size: 42px;
    color: black;
    font-weight: bold;
  }
  .fontLargeTitle {
    font-size: 28px;
  }

  .featuresSidePadding {
    padding-left: 250px;
    padding-right: 250px;
  }
}
@media (min-width: 1800px) {
  .extraSidePadding {
    padding-left: 500px;
    padding-right: 500px;
  }
}
