.item {
  display: none;
}
.titleMargin {
  padding-left: 20px;
}
.burgerIcon {
  display: block;
  visibility: visible;
}
.navbarModal {
  visibility: visible;
}

@media (min-width: 768px) {
  .item {
    display: block;
  }
  .titleMargin {
    padding-left: 40px;
  }
  .burgerIcon {
    visibility: hidden;
  }
  .navbarModal {
    visibility: hidden;
  }
}
@media (min-width: 1200px) {
  .item {
    display: block;
  }
  .titleMargin {
    padding-left: 40px;
  }
  .burgerIcon {
    visibility: hidden;
  }
  .navbarModal {
    visibility: hidden;
  }
}
